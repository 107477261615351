<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card max-width="1100" width="100%" flat class="transparent mb-0 mb-md-5">
        <v-card-title flat class="transparent">
          <div class="d-flex">
            <h1 class="headline">{{ $t('medicalTests.analytical_tests_list_types') }}</h1>
          </div>
          <v-spacer />
          <v-btn data-test="test-button-new-type" depressed color="primary" class="order-md-2" :to="{ name: paths.createMedicalTest }">
            {{ $t('medicalTests.new_type_of_test') }}
          </v-btn>
        </v-card-title>
        <div>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :loading-text="$t('common.cargando')"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              item-key="code"
              :no-data-text="$t('medicalTests.no_data')"
              @page-count="pageCount = $event"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-left">
                    {{ item.code }}
                  </td>
                  <td class="text-left">
                    {{ item.description }}
                  </td>
                  <td class="text-left">
                    {{ item.createdAt | moment('DD/MM/YYYY') }}
                  </td>

                  <td class="text-center">
                    <v-menu v-if="canManage(item)" bottom right dense>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="grey" v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="edit(item.id)">
                          <v-list-item-title>{{ $t('common.edit') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openDeleteDialog(item.id)">
                          <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
              <template slot="loading">
                <v-skeleton-loader loading type="table-row-divider@6" />
              </template>
            </v-data-table>

            <v-dialog v-model="dialogDelete.dialog" max-width="500px">
              <v-card>
                <v-toolbar flat>
                  <v-toolbar-title>{{ dialogDelete.title }}</v-toolbar-title>
                  <v-spacer />
                  <v-btn icon @click="dialogDelete.dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <p>{{ dialogDelete.text }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="grey darken-1" text @click="dialogDelete.dialog = false">{{
                    $t('common.cancel')
                  }}</v-btn>
                  <v-btn
                    :color="dialogDelete.color + ' darken-1'"
                    depressed
                    dark
                    @click="
                      dialogDelete.dialog = false;
                      executeAction();
                    "
                    >{{ $t('common.confirm') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
          <div class="text-center mt-3">
            <v-pagination v-model="page" :length="pageCount" circle />
          </div>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { baseNameRoutes } from '@/router/paths';
import { getHeader, medicalTestsUrl } from '@/config/config';
import Alerts from '@/mixins/Alerts';

const ANALYTICAL_TYPE = 'analytical';
const MEDICAL_TESTS_ENDPOINT = `${medicalTestsUrl}medical-order-templates?type=${ANALYTICAL_TYPE}`;

export default {
  mixins: [Alerts],
  data() {
    return {
      paths: {
        createMedicalTest: baseNameRoutes.createMedicalTest,
      },
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: true,
      search: '',
      headers: [
        { text: this.$t('medicalTests.code'), align: 'left', value: 'code' },
        { text: this.$t('medicalTests.description'), align: 'left', value: 'description', width: '70%' },
        { text: this.$t('medicalTests.created_at'), align: 'left', value: 'createdAt' },
        { text: this.$t('common.actions'), align: 'center', value: 'actions', sortable: false },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 25,
      items: [],
      dialogDelete: {
        dialog: false,
        title: '',
        text: '',
        icon: '',
        color: '',
        id: 0,
      },
      dialog_new: {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
      },
    };
  },

  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
    }),

    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    isAdmin() {
      return this.authUser && typeof this.authUser['rol'] !== 'undefined' && this.authUser['rol'] === 1;
    },
  },

  mounted() {
    this.getMedicalTestsData();
  },

  methods: {
    getMedicalTestsData() {
      this.loading = true;

      this.$http
        .get(MEDICAL_TESTS_ENDPOINT, { headers: getHeader() })
        .then(res => {
          this.items = res.body.templates;
          this.page = res.body.meta.currentPage;
          this.itemsPerPage = res.body.meta.size;
        })
        .catch(err => {
          if (err.status === 404) {
            this.goNotFound();
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    redirectToDashboard() {
      this.$router.push({ name: baseNameRoutes.dashboard });
    },

    edit(id) {
      this.$router.push(`/pruebas/tipos/${id}`);
    },

    canManage(item) {
      return (this.isAdmin && item.professionalId === null) || (!this.isAdmin && item.professionalId);
    },

    openDeleteDialog(id) {
      this.dialogDelete.dialog = true;
      this.dialogDelete.title = this.$t('medicalTests.delete_test');
      this.dialogDelete.text = this.$t('medicalTests.confirm_delete_test');
      this.dialogDelete.icon = 'mdi-delete';
      this.dialogDelete.color = 'red';
      this.dialogDelete.id = id;
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    executeAction() {
      try {
        this.$http
          .delete(`${MEDICAL_TESTS_ENDPOINT}/${this.dialogDelete.id}`, { headers: getHeader() })
          .then(response => {
            if (response.status === 200) {
              this.toastSuccess(this.$t('success.delete_common'));
              this.items = this.items.filter(item => item.id !== this.dialogDelete.id);
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
          });
      } catch (e) {
        this.toastError(this.$t('errors.try_again'));
      }
    },
  },
};
</script>
